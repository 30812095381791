import { Avatar, Badge, Button, Grid2, Typography } from '@mui/material';
import { useApp } from 'app/shared/hooks';
import { formatCpf, Theme } from 'app/shared/utils';
import { MdOutlineModeEdit, MdPerson } from 'react-icons/md';
import EditUserModal from '../EditUserModal';
import { useState } from 'react';
import EditFotoUserModal from '../EditFotoUserModal';
import ChangePasswordModal from '../ChangePasswordModal';

const UserProfile = () => {
    const { user } = useApp();
    const [editUserModal, setEditUserModal] = useState(false)
    const [editFotoUserModal, setEditFotoUserModal] = useState(false)
    const [alteraSenhaModal, setAlteraSenhaModal] = useState(false)
    const aluno = user?.relacionado?.[0]
    if (!aluno) return null;
    const profilePic = user.foto;


    return (
        <Grid2 display="flex" flexDirection="column" alignItems="center">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClick={() => { setEditFotoUserModal(true) }}
                badgeContent={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '5px',
                            background: 'white',
                            border: '1px solid #CDCDCD',
                            borderRadius: '30px',
                            cursor: 'pointer',
                        }}
                    >
                        <MdOutlineModeEdit />
                    </div>
                }
            >
                <Avatar onClick={() => { setEditFotoUserModal(true) }} src={profilePic} sx={{ bgcolor: '#CDCDCD', width: 90, height: 90, cursor: 'pointer' }}>
                    {profilePic ? 'foto' : <MdPerson size={30} />}
                </Avatar>
            </Badge>

            <Typography
                color={Theme.colors.black}
                mt="16px"
                fontWeight="500"
                textAlign="center"
                fontSize="22px"
            >
                {aluno.nome}
            </Typography>
            {aluno.cpf && <Typography textAlign='center' fontSize='14px'>
                {formatCpf(aluno.cpf)}
            </Typography>}
            <Typography textAlign="center" fontSize="12px">
                {aluno.email}
            </Typography>
            <Typography textAlign="center" fontSize="12px">
                {aluno.grau_instrucao.nome}
            </Typography>
            <Typography textAlign="center" fontSize="14px">
                {aluno.instituicao_ensino.nome}
            </Typography>

            <Button sx={{ mt: '30px' }} onClick={() => setEditUserModal(true)} fullWidth variant="contained">
                EDITAR PERFIL
            </Button>
            <Button sx={{ mt: '10px' }} onClick={() => setAlteraSenhaModal(true)} fullWidth variant="text">
                ALTERAR SENHA
            </Button>
            {editUserModal && <EditUserModal
                open={editUserModal}
                onClose={() => setEditUserModal(false)}
            />}
            {editFotoUserModal && <EditFotoUserModal
                open={editFotoUserModal}
                onClose={() => setEditFotoUserModal(false)}
            />}
            {alteraSenhaModal && <ChangePasswordModal
                open={alteraSenhaModal}
                onClose={() => setAlteraSenhaModal(false)}
            />}
        </Grid2>
    );
};

export default UserProfile;
