import { Grid2 } from "@mui/material"
import UserProfile from "./components/UserProfile/UserProfile"
import { TextList } from "./components"

const HomePage = () => {
    return (
        <Grid2 container spacing={2}>
            <Grid2
                size={{ xs: 12, sm: 4, md: 3 }}
                minHeight='300px'
                height='fit-content'
                borderRadius="4px"
                border="1px solid #CDCDCD"
                padding="20px"
                bgcolor="white"
            >
                <UserProfile />
            </Grid2>

            <Grid2
                size={{ xs: 12, sm: 8, md: 9 }}
                height="100%"
                borderRadius="4px"
                border="1px solid #CDCDCD"
                padding="20px"
                bgcolor="white"
            >
                <TextList />
            </Grid2>

        </Grid2>
    )
}

export default HomePage